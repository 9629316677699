








import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { useAddresses } from '~/modules/account/composables/useAddresses';
import { CustomerAddress } from '~/modules/GraphQL/types';
import { SubmitEventPayload } from '~/modules/account/types/form';
import AddressForm from '~/modules/account/pages/MyAccount/AddressesDetails/AddressForm.vue';
import useUiNotification from '~/composables/useUiNotification';

export default defineComponent({
  name: 'AddressNew',
  components: { AddressForm },
  setup(prop, { emit }) {
    const context = useContext();
    const useAddressesComposable = useAddresses();
    const { send: sendNotification } = useUiNotification();
    const createAddress = async ({
      form,
    }: SubmitEventPayload<CustomerAddress>) => {
      await useAddressesComposable.save({ address: form });
      // await router.push(context.localeRoute({ name: 'account-addresses-details' }));
      sendNotification({
        id: Symbol('address_updated'),
        message: context.app.i18n.t('Create address successfully') as string,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Create',
      });
      emit('onFinish');
    };

    return { createAddress };
  },
});
